body {
    background: rgb(52, 52, 52);
    padding: 30px;
}
.app {
    display: flex;
    flex-direction: column;
    align-items: center;
}
article {
    display: flex;
    flex-direction: column;
    align-items: center;
}
header, .qnaContainer {
    color: white;
}
.actionButtons, .qnaContainer {
    max-width: 100vw;
    padding: 20px;
    box-sizing: border-box;
}
.actionButtons {
    margin: 15px 0px;
    text-align: center;
}
.actionButtons button {
    font-size: 1.2em;
    margin: 0px 0.3em;
}
.timetablePreview {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
h1 {
    text-align: center;
}
.timetablePreview table.classesAdded {
    background: whitesmoke;
    border-collapse: collapse;
}
.timetablePreview table.classesAdded td,
.timetablePreview table.classesAdded th {
    border: 1px solid black;
    padding: 10px;
}

footer {
    padding: 2em;
    color: white;
    text-align: center;
}
a {
    color: rgb(47, 173, 131);
}
a:hover {
    color: rgb(36, 129, 98);
}
.timetable > svg {
    max-width: calc(100vw - 40px);
}