.inputBubble {
    border-radius: 15px 15px;
    padding: 3px 10px;
    font-size: 15px;
    border: 1px solid rgb(22, 133, 133);
    background: rgb(243, 243, 243);
    color: black;
    cursor: pointer;
}

.inputBubble:hover {
    background: rgb(196, 218, 218);
}

.inputBubble.hasValue {
    background: rgb(22, 133, 133);
}