
table.subjects {
    background: whitesmoke;
    border-collapse: collapse;
    font-size: 12px;
    min-width: 80vw;
}
table.subjects td, table.subjects th {
    padding: 10px;
    border: 1px solid black;
}
table.subjects td, table.subjects th {
    word-break: keep-all;
    white-space: nowrap;
}
.deleteButton {
    color: red;
}